import React from 'react'
import Layout from '../../components/Layout'
import ContactForm from "../../components/ContactForm";

export default class Index extends React.Component {
    render() {
        return (
            <Layout>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <div className={'columns is-variable is-6'}>
                                <div className={'column is-ligna-font'}>
                                    <h1 className={'font-primary'}>Contact</h1>
                                    <p className={'pt-1'}>Get in touch for any clarifications or questions you may have at any stage of a project.<br/> We would be happy to chat through what might be needed and how we can help.</p>
                                    <p>Feel free to give us a call during working hours, or send us and email with any questions or to arrange a callback. <br/>Alternatively, use the contact form here and we will get back to you as soon as possible.</p>
                                    <a href={'tel:01284598008'}
                                       className={'font-primary'}>
                                        <div className={'columns mt-2'}>
                                            <div className={'column px-2 my-auto is-narrow'}>
                                                <div className={'icon-circle-container mb-0'}>
                                                    <div className={'icon-circle'}>
                                                        <i className={'fal fa-phone'}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'column my-auto'}>
                                                <h5 className={'font-primary mb-0'}>
                                                    01284 598008</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={'mailto:info@lignaconsultancy.co.uk'}
                                       className={'font-primary'}>
                                        <div className={'columns mt-3'}>
                                            <div className={'column px-2 my-auto is-narrow'}>
                                                <div className={'icon-circle-container mb-0'}>
                                                    <div className={'icon-circle'}>
                                                        <i className={'fal fa-envelope'}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'column my-auto'}>
                                                <h5 className={'font-primary mb-0'}>
                                                    info@lignaconsultancy.co.uk</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className={'column'}>
                                    <ContactForm name={'contact'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
